import { Checkbox, CheckboxGroupProps, Grid, Group, } from "@mantine/core";
import { Ref, forwardRef, useContext, } from "react";
import { InstantSearch, useRefinementList, } from "react-instantsearch";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import { MyUserDataContext } from "../contexts";
import { createRenderingInfoPrinter } from "../utils";


const debug = createRenderingInfoPrinter("SearchBoxComponent");

interface CustomerTagSelectComponentProps {
    defaultValue?: string[];
    textProps?: Partial<CheckboxGroupProps>;
    onChange: (item: string[]) => void,
    // setTagsList: (tags: string[]) => void
    tagsList: string[]

}

export interface AutocompleteComponentHandle {

}

const SearchBoxComponent = forwardRef<AutocompleteComponentHandle, CustomerTagSelectComponentProps>(
    ({ textProps, defaultValue, tagsList, onChange, ...props }: CustomerTagSelectComponentProps, ref: Ref<AutocompleteComponentHandle>) => {
        debug();


        // const [facets, setFacets] = useState<any>({})
        // const { results, refresh, } = useInstantSearch();



        const {
            items,
            refine,

        } = useRefinementList({ ...props, attribute: "holdings_arr.fund" });

        return <Grid >

            <>



                <Checkbox.Group
                    {...textProps}
                    style={{ width: "100%" }}

                    onChange={
                        (items) => {
                            if (onChange) {
                                onChange(items)
                            }
                        }
                    }
                    value={tagsList}
                >
                    <Group mt="xs">

                        {items.map((item) => (



                            <Checkbox
                                type="checkbox"
                                color="green"
                                checked={item.isRefined}
                                onChange={() => {
                                    refine(item.value)
                                }}
                                value={item.value}
                                label={<>
                                    <span>{item.label.replaceAll("_", " ").toUpperCase()}</span>
                                    <span> ({item.count})</span>
                                </>}
                            />



                        ))}
                    </Group>
                </Checkbox.Group>

            </>
        </Grid >

    }
);

export const CustomerTagSelectComponent = forwardRef<AutocompleteComponentHandle, CustomerTagSelectComponentProps>(
    ({ textProps, defaultValue, onChange, tagsList, }: CustomerTagSelectComponentProps, ref: Ref<AutocompleteComponentHandle>) => {
        const userData = useContext(MyUserDataContext);

        const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
            server: {
                apiKey: userData.customerCollectionSearchKey!, // Be sure to use a Search API Key
                nodes: [
                    {
                        host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                        port: 443,
                        protocol: 'https',

                    },

                ],


            },
            additionalSearchParameters: {
                query_by: "holdings_arr",
                facet_by: "holdings_arr.fund",
                max_facet_values: 999999,

            }
        });

        const typesenseSearchClient = typesenseInstantsearchAdapter.searchClient;

        return (
            <InstantSearch indexName={process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION as string}

                searchClient={typesenseSearchClient}>
                <SearchBoxComponent tagsList={tagsList} textProps={textProps} defaultValue={defaultValue} onChange={onChange} />
            </InstantSearch>
        );
    }
);
