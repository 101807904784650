import { Badge, Button, Card, Container, Group, Image, Text } from '@mantine/core';

const CommingSoon = () => {





    return <Container style={{ height: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }} >

        <Image
            src="./coming-soon.png"
            height={"100%"}
        // alt="Norway"
        />
    </Container>
};



export default CommingSoon;