import DataTable, { SortOrder, TableColumn } from "react-data-table-component";
import { Card, Group, Loader, Space } from "@mantine/core";

import { onSnapshot, collection, collectionGroup, query, QuerySnapshot, startAfter, limit, QueryDocumentSnapshot, orderBy, getCountFromServer, limitToLast, endBefore, where, OrderByDirection } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import useLocalStorage from "use-local-storage";
import { useFirestore } from "../helpers/firebaseContext";

const customStyles = {
  table: {
    style: {
      minHeight: "500px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};
type CustomDataTablePropTypes = {
  tableKey: string;
  columns: {}[];
  CustomSearchFields?: any;
  orderByAttribute: string
  orderDirection?: OrderByDirection
  filterAttributes?: string,
  [x: string]: any;
  defaultPageSize?: number
};
const CustomDataTable: React.FC<CustomDataTablePropTypes> = ({
  tableKey,
  columns,
  CustomSearchFields,
  customFilters,
  customQueries,
  reloadData,
  isCollectionGroup,
  extraQueryParams,
  orderByAttribute,
  orderDirection,
  filterAttributes,
  defaultPageSize,
  ...props
}) => {
  const db = useFirestore();
  // const [page, setPage] = useLocalStorage<number>(
  //   tableKey + "-page",
  //   50
  // );
  const [tableData, setTableData] = useState<QueryDocumentSnapshot<any>[]>([]);

  const [page, setPage] = useState<number>(1);
  //because Firestore has this amazing idea where it sucks, we need to keep track of the previous page number so we can know if we're going forwards or backwards.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [previousPageNumber, setPreviousPageNumber] = useState<number>(
    1);
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>(
    tableKey + "-rowsPerPage",
    100
  );

  const [firstDocInCurrentPage, setFirstDocInCurrentPage] = useState<QueryDocumentSnapshot<any>>();
  const [lastDocInCurrentPage, setLastDocInCurrentPage] = useState<QueryDocumentSnapshot<any>>();
  const [resetTable, setResetTable] = useState<boolean>(false)


  const [totalData, setTotalData] = useState(0)
  const [pending, setPending] = useState<boolean>(true);
  const [sortData, setSortData] = useState<{sortField:string, isAsc:boolean} | undefined>();
  const handleChange = ({ selectedRows }: any) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    if (props.setSelectedRows) {
      props.setSelectedRows(selectedRows);
    }
  };

  const subHeaderComponentMemo = React.useMemo(() => {
    return <Group justify="space-between">{CustomSearchFields}</Group>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.selectedRows,
    customFilters,
    customQueries,
    filterAttributes
  ]);







  const handlePageChange = (value: number, totalRows: number) => {


    // setPreviousPageNumber(page);
    setPage(value)
  }
  const handleRowsCountChange = (value: number) => {
    setRowsPerPage(value)

  }

  const createQuery = () => {

    let q = query(collection(db, tableKey));
    if (isCollectionGroup) {
      q = query(collectionGroup(db, tableKey))
    }



    for (let key in customQueries) {
      if (customQueries[key]?.length) {

        for (let i = 0; i < customQueries[key].length; i++) {
          console.log("We are adding query!");
          q = query(q, customQueries[key][i]);
        }
      }
    }
    if (extraQueryParams) {
      q = query(q, extraQueryParams);
    }
    return q
  }


  useEffect(() => {
    const fetchData = async () => {
      setPending(true);

      let customOrderBy = orderByAttribute;
      let customOrderDirection: OrderByDirection = orderDirection ?? "asc"
      if (sortData) {
        customOrderBy = sortData.sortField;
        customOrderDirection = sortData.isAsc ? "asc":"desc";
      }

      let q = createQuery()
      let rows = tableData || [];
      if (filterAttributes?.length) {
        if (customFilters[tableKey + "-searchText"]?.length)
          q = query(q,
            where(filterAttributes, '>=',
              customFilters[tableKey + "-searchText"])
            ,
          )
        if (filterAttributes !== customOrderBy)
          q = query(q,
            orderBy(filterAttributes))
      }

      await getCountFromServer(q).then((result) => {
        if (result.data().count !== totalData || totalData === 0) {
          setTotalData(result.data().count);
        }
        return result.data().count
      });

      const goingForward = page > previousPageNumber;
      if (page === previousPageNumber) {

        q = query(q, orderBy(customOrderBy,customOrderDirection),);


      }
      else if (goingForward && lastDocInCurrentPage) {
        q = query(q, orderBy(customOrderBy,customOrderDirection), startAfter(lastDocInCurrentPage), limit(rowsPerPage));
      }
      else if (!goingForward && firstDocInCurrentPage) {
        q = query(q, orderBy(customOrderBy,customOrderDirection), endBefore(firstDocInCurrentPage), limitToLast(rowsPerPage));
      }



      //we are returning here, because onSnapshot stays active even after the component is unmounted, and it causes a memory leak
      //we need to return the unsubscribe function so that it can be called when the component is unmounted
      const unsubscribe = onSnapshot(
        query(q, limit(rowsPerPage))
        ,
        (snapshot: QuerySnapshot<any>) => {

          // if (snapshot.metadata.fromCache) {
          //   console.log("Cached data.  Not using it to populate table.", snapshot.docs.length);
          //   // return;

          // } else {
          //   console.log("Server data", snapshot.docs.length);
          // }

          rows = snapshot.docs



          setPending(false);

          if (snapshot.empty) {
            setTableData([]);

            return;
          }
          if (sortData) {
            rows = rows.sort((a, b) => {
            const nameA = a.data()[sortData?.sortField??""]?? ''; // Handle potentially undefined and case sensitivity
            const nameB = b.data()[sortData?.sortField??""]?? ''; // Same as above
            if (nameA < nameB) {
              if (sortData?.isAsc) {
                return -1; // nameA comes first
              }else {
                return 1; // nameB comes first
              }
                
            }
            if (nameA > nameB) {
              if (sortData?.isAsc) {
                return 1; // nameB comes first
              }else {
                return -1; // nameA comes first
              }
            }
            return 0; // names are equal
        });
          }
          

          setTableData(rows);

          setFirstDocInCurrentPage(rows[0]);
          setLastDocInCurrentPage(rows[rows.length - 1]);

          // filterTheTable();
        },
        (error) => {
          setPending(false);

          console.log(error);
        }
      );
      setPending(false);

      return () => unsubscribe();
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, customQueries, page, customFilters, filterAttributes, sortData]); //kycStatusSelections]); //the damn thing went into an infinite loop, therefore I'm only using "pending" to check if the data is ready.  React doesn't handle Object/Array very well for updates
  useEffect(() => {
    setResetTable(!resetTable)
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customFilters, filterAttributes, rowsPerPage]
  )

  const handleSort = (column:TableColumn<QueryDocumentSnapshot<any>>, sortDirection:SortOrder) => {
    console.log(`Sorted: ${column.sortField}, ${sortDirection}`);
    // You can perform additional actions here
    if (column.sortField) {
      setSortData({sortField:column.sortField, isAsc:sortDirection === "asc"})
    }
  };

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      {subHeaderComponentMemo}
      <Space h="xl" />
      <DataTable
        {...props}
        columns={columns}
        data={tableData || []}
        customStyles={customStyles}
        // pagination
        progressPending={pending}
        progressComponent={<Loader />}
        paginationServer={true}
        onChangePage={handlePageChange}

        pagination
        paginationIconLastPage={<div onClick={() => null}>
        </div>}
        // paginationServerOptions={
        //   { persistSelectedOnPageChange: true }
        // }
        paginationDefaultPage={1}
        paginationResetDefaultPage={resetTable}
        paginationIconFirstPage={<div onClick={() => null}>
        </div>}
        paginationPerPage={rowsPerPage}
        onChangeRowsPerPage={handleRowsCountChange}
        onSelectedRowsChange={handleChange}
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationTotalRows={totalData}
        onSort={(column, sortDirection) => handleSort(column, sortDirection)}
      />
    </Card>
  );
};

export default CustomDataTable;