import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { connectAuthEmulator } from 'firebase/auth';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { connectStorageEmulator } from 'firebase/storage';
import { connectFunctionsEmulator } from 'firebase/functions';
import { IntlProvider } from "react-intl";
import { useEffect, useState } from 'react';
import FirebaseProvider from './helpers/firebaseProvider';
import { auth, firestore, functions, storage } from './helpers/firebaseContext';
import * as Sentry from "@sentry/react";
/*
//hasbean@gmail.com
const firebaseConfig = {
  apiKey: "AIzaSyBLkMKsPKmH_p9tPxFRQzn6hSDuhpJe7wA",
  authDomain: "kfhc-crm.firebaseapp.com",
  projectId: "kfhc-crm",
  storageBucket: "kfhc-crm.appspot.com",
  messagingSenderId: "968323732905",
  appId: "1:968323732905:web:ffae9cfefe0214cb7d60b8",
  measurementId: "G-8BB2LFHZDZ"
};
*/

// console.log("Environment is", process.env.REACT_APP_ENV);

//yalhusayan@kfhcapital.com.kw test
let firebaseConfig = {
  apiKey: "AIzaSyAyIZBEmpfIpG7VzztGLdL_1XnphB72rYw",
  authDomain: "kfh-capital-crm-test.firebaseapp.com",
  projectId: "kfh-capital-crm-test",
  storageBucket: "kfh-capital-crm-test.appspot.com",
  messagingSenderId: "17095975918",
  appId: "1:17095975918:web:2479c809b213aad758a4ff",
  measurementId: "G-S5H54FBK7P"
};

if (process.env.REACT_APP_ENV === 'production') {
  //yalhusayan@kfhcapital.com.kw production
  firebaseConfig = {
    apiKey: "AIzaSyBbljwec4j9D9mn3zS8kgVtJtsT-fm9coI",
    // authDomain: "kfh-capital-crm.firebaseapp.com",
    authDomain: "portal.kfhcapital.com.kw",
    projectId: "kfh-capital-crm",
    storageBucket: "kfh-capital-crm.appspot.com",
    messagingSenderId: "633630348973",
    appId: "1:633630348973:web:a3b1c062f514877345d824",
    measurementId: "G-R83SFVRWMZ"
  };
}





Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});






const container = document.getElementById('root')!;
const root = createRoot(container);

const LocalizationWrapper = () => {
  const [locale, setLocale] = useState("en");
  const [messages, setMessages] = useState<any>(null);
  function loadMessages(locale: string) {
    switch (locale) {
      case "ar":
        return import("./lang/ar.json");
      case "en":
        return import("./lang/en.json");

      default:
        return import("./lang/en.json");
    }
  }
  useEffect(() => {
    loadMessages(locale).then(setMessages);
  }, [locale]);

  return messages ? (
    <IntlProvider locale={locale} messages={messages}>
      <App />
    </IntlProvider>
  ) : <App />;
}
export default LocalizationWrapper;


//glooobul
function GlobulConfig(props: any) {



  console.log(process.env);
  functions.region = 'europe-west1';
  const OVERRIDE_FIREBASE = (process.env.REACT_APP_OVERRIDE_FIREBASE_CONFIG === "true" || process.env.REACT_APP_OVERRIDE_FIREBASE_CONFIG === "True")


  if (process.env.NODE_ENV !== 'production' && OVERRIDE_FIREBASE) {

    connectFirestoreEmulator(firestore, process.env.REACT_APP_FIRESTORE_EMULATOR_HOST || "localhost", Number(process.env.FREACT_APP_IRESTORE_EMULATOR_PORT) || 8080);
    connectStorageEmulator(storage, process.env.REACT_APP_STORAGE_EMULATOR_HOST || "localhost", Number(process.env.REACT_APP_STORAGE_EMULATOR_PORT) || 9199);
    connectFunctionsEmulator(functions, process.env.REACT_APP_FUNCTIONS_EMULATOR_HOST || "localhost", Number(process.env.REACT_APP_FUNCTIONS_EMULATOR_PORT) || 5001);
    connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR_HOST || "http://localhost:9099");

  }

  return (
    <>
      {props.children}
    </>
  );
}



root.render(
  <>
    {/* <React.StrictMode> */}

    <Provider store={store}>
      <FirebaseProvider>
        {/* <FunctionsProvider sdk={getFunctions(useFirebaseApp())}> */}

        <BrowserRouter>
          <GlobulConfig>
            <LocalizationWrapper />



          </GlobulConfig>
        </BrowserRouter>
        {/* </FunctionsProvider> */}

      </FirebaseProvider>,

    </Provider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
