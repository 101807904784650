import { ActionIcon, Button, Card, Checkbox, Divider, Grid, Input, InputBase, Loader, Pill, Select, SelectProps, Text, Textarea, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { useContext, useEffect, useState } from 'react';
import { BaseResult, CreateCampaignRequest, Customer, Fund, FundAutoComplete, FundID, GenericMessageResult, RMUser, TwilioContentType } from '../../types';
import { RmsSelectComponent } from '../../components/rms_select_component';
import { CustomerSelectComponent } from '../../components/customer_select_component';
import { CustomerTagSelectComponent } from '../../components/customer_tag_select_component';
import { RmSegmentSelectComponent } from '../../components/rm_segment_select_component';
import { replaceText } from '../../helpers/common';
import { httpsCallable } from 'firebase/functions';
import { MessagesSent, refreshEmailTemplates, refreshWhatsappTemplates } from '../../components/send_sms_modal';
import { useFirestore, useFunctions } from '../../helpers/firebaseContext';
import { IconRefresh } from '@tabler/icons-react';
import { AppConfigContext, } from '../../contexts';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
// import { useDisclosure } from '@mantine/hooks';


const CreateCampaign = () => {
    const [chosenCustomersList, setChosenCustomersList] = useState<Customer[]>([])
    const [smsSampleText, setSMSSampleText] = useState("");
    const [emailSampleText, setEmailSampleText] = useState("");
    const [notificationSampleText, setNotificationSampleText] = useState("");
    const [SMSDocID, setSMSDocID] = useState<string[]>();
    const [whatsappTemplates, setWhatsappTemplates] = useState<TwilioContentType[]>([]);
    const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] = useState<TwilioContentType & SelectProps>();
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<any>();
    const [emailTemplates, setEmailTemplates] = useState<any[]>([]);
    const [loadingEmailTemplates, setLoadingEmailTemplates] = useState<boolean>(false);
    const [loadingWhatsappTemplates, setLoadingWhatsappTemplates] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>();
    const [selectedNotificationDataType, setSelectedNotificationDataType] = useState<string>("none");
    const [selectedNotificationDataValue, setSelectedNotificationDataValue] = useState<string | null>(null);
    const [funds, setFunds] = useState([] as FundAutoComplete[]);
    const [selectedFund, setSelectedFund] = useState<Fund | null>(null);


    // const [visible, { close, open }] = useDisclosure(false);

    const { apiUrl } = useContext(AppConfigContext);

    const functions = useFunctions();
    const db = useFirestore();
    const navigate = useNavigate();


    const sampleObj = {
        name_en: "Yousef",
        name_ar: "يوسف",
    };
    const MessageTextchanged = (text: string, stateFunction: (value: string) => void) => {
        let s = sampleObj;
        //ok figure out sample text stuff processing
        stateFunction(replaceText(text, s));
    };
    // const db = useFirestore();

    const form = useForm<
        CreateCampaignRequest
    >({
        initialValues: {
            title: "",
            rmRecipients: [],
            segmentsRecipients: [],
            customerRecipients: [],
            tagsRecipients: [],
            smsMessage: "",
            type: [],
            updateLink: {
                updateType: "broadcast",
            },


        },
        // validate: {
        //     notificationDataLink: (value) =>{
        //         const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
        //           return urlPattern.test(value ?? "") ? null : 'Invalid URL';
        //     }
        // }
    });


    useEffect(() => {
        form.setFieldValue("customerRecipients", chosenCustomersList?.map((customer) => customer.id!))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenCustomersList])
    //get funds
    useEffect(() => {
        // check if a civil id is passed in url query params 
        // if (civilIDParam && civilValid(civilIDParam)) {
        //   setID(civilIDParam)
        // }
        //get current funds with active == true


        const fundCollection = collection(db, "funds");
        const q = query(fundCollection, where("active", "==", true));

        return onSnapshot(q, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("Cached data", snapshot.docs.length);
                // return;
            } else {
                console.log("Server data", snapshot.docs.length);
            }

            // let dict = {} as any;
            let fundArray = [] as FundAutoComplete[];


            snapshot.docs.forEach((doc) => {
                var data = doc.data() as FundAutoComplete;
                //TODO: for now, only return funds with ibans available (open ended).  Later, we need to have a flag for the type of fund
                if (!data.iban) return;
                data["id"] = doc.id as FundID;
                data["value"] = doc.id
                data["label"] = `${data.name_en} - ${data.name_ar} `

                fundArray.push(data);
                // return data;
            });
            // console.log("funds", rows);
            setFunds(fundArray);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    // useEffect(() => {
    //     const getData = async () => {

    //         await refreshWhatsappTemplates(form.values.type.includes("whatsapp"), form, apiUrl!, setWhatsappTemplates, setSelectedWhatsappTemplate, false, setLoadingWhatsappTemplates)
    //         await refreshEmailTemplates(form.values.type.includes("email"), form, apiUrl!, setEmailTemplates, setSelectedEmailTemplate, setLoadingEmailTemplates)

    //     }
    //     if (form.values.type?.length) { getData() }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [form.values.type])


    function transformFundsToGroupedData(funds: FundAutoComplete[]) {
        // Group funds by currency or any other property
        const grouped = funds.reduce((acc, fund) => {
            const groupName = fund.currency; // Group by currency
            if (!acc[groupName]) {
                acc[groupName] = { group: groupName, items: [] };
            }
            // Add the fund to the appropriate group
            acc[groupName].items.push({ value: fund.id, label: fund.name_en }); // Adjust label as needed
            return acc;
        }, {} as any);

        // Convert the grouped object back into an array
        return Object.values(grouped) as any;
    }




    return (
        <Grid justify='center' bg={"white"} h="100%">
            <Grid.Col span={{ xs: 12 }}>


                <Title
                    ms={30}
                    mt={30}
                    mb={20}
                    order={4}
                    ta="left">Create Campaign</Title>
            </Grid.Col>
            {/* <LoadingOverlay visible={visible} loaderProps={{ children: 'Loading...' }} /> */}

            <Grid.Col span={{ lg: 8 }}>

                <form
                    onSubmit={form.onSubmit(async (values) => {
                        setErrMsg(undefined)

                        if (values.customerRecipients.length <= 0 && values.tagsRecipients.length <= 0) {
                            setErrMsg("either customers or tags need to be added");
                            return;
                        }
                        if (values.type.length <= 0) {
                            setErrMsg("atleast one type must be selected");
                            return;
                        }


                        setLoadingSubmit(true)
                        try {



                            const createCampaign = httpsCallable<CreateCampaignRequest, BaseResult>(functions, "createCampaign");
                            const response = await createCampaign(values)
                            setLoadingSubmit(false);
                            navigate("/campaigns");
                            return;

                            // if (response?.data?.messageID) {

                            //     setSMSDocID([response?.data?.messageID]);
                            //     setLoadingSubmit(false);


                            // }
                            // if (response?.data?.messageIDsList) {
                            //     setSMSDocID(response?.data?.messageIDsList);
                            //     setLoadingSubmit(false);

                            // }


                        }
                        catch (e: any) {
                            if (e?.message) {
                                setErrMsg(e?.message)

                            }
                            else {
                                setErrMsg("an unexpcted error has occured ")

                            }

                            setLoadingSubmit(false);

                        }

                    })}
                >
                    <Grid>

                        <Grid.Col span={{ xs: 12 }}>
                            <TextInput
                                label="Title"
                                required
                                // placeholder=''
                                // mt={10}
                                {...form.getInputProps('title')}
                                value={form.values.title}
                                error={form.errors.title}
                                onChange={(e) => {
                                    form.setFieldValue("title", e.target.value)
                                }}
                            />
                        </Grid.Col>

                        <Grid.Col span={{ lg: 4 }}>
                            <CustomerSelectComponent
                                textProps={{
                                    label: "Search Customers by (ID / Name / Tag)",
                                    required: false
                                }}
                                submitType="object"
                                handleSubmit={(customerUserObj) => {
                                    let customerUser = customerUserObj as Customer
                                    if (customerUser) {
                                        const dounfRm = chosenCustomersList.find((chosenRm) => chosenRm.id === customerUser.id)
                                        if (!dounfRm) {
                                            setChosenCustomersList([...chosenCustomersList, customerUser])
                                        }
                                    }
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ lg: 8 }}>
                            <InputBase component="div" multiline
                                placeholder='Chosen Customers'
                                label="Chosen Customers"
                            >
                                <Pill.Group
                                    placeholder='Chosen Customers'
                                >{chosenCustomersList.map((chosenCustomer) => <Pill
                                    withRemoveButton
                                    onRemove={() => {
                                        const updatedCustomersList = chosenCustomersList.filter((customerUser) =>
                                            customerUser.id !== chosenCustomer.id
                                        )
                                        setChosenCustomersList(updatedCustomersList)
                                    }}
                                >
                                    {chosenCustomer?.name_en} -  {chosenCustomer?.id}
                                </Pill>)}
                                </Pill.Group>
                            </InputBase>
                        </Grid.Col>
                        <Divider my="md" w="100%" />

                        <Grid.Col span={{ xs: 12 }}>
                            {/* <Text mb={10}>
                                Tags
                            </Text> */}
                            <CustomerTagSelectComponent
                                textProps={{
                                    label: "Tags",
                                    required: false
                                }}
                                tagsList={form.values.tagsRecipients}
                                // setTagsList={(values) => form.setFieldValue("tagsRecipients", values)}
                                onChange={(value) => {
                                    form.setFieldValue("tagsRecipients", value)
                                }}
                            />
                        </Grid.Col>
                        <Divider my="md" w="100%" />

                        <Grid.Col span={{ xs: 12 }} >
                            <Checkbox.Group
                                // name="type"
                                label="Select message type"
                                // description="This is anonymous"
                                withAsterisk
                                color="green"

                                onChange={(value) => {
                                    const chosenTypes = value as ("sms" | "email" | "customEmail" | "whatsapp" | "notification")[]
                                    if (!form.values.type.includes("whatsapp") && chosenTypes.includes("whatsapp")) {
                                        refreshWhatsappTemplates(true, form, apiUrl!, setWhatsappTemplates, setSelectedWhatsappTemplate, false, setLoadingWhatsappTemplates)
                                    }
                                    if (!form.values.type.includes("email") && chosenTypes.includes("email")) {
                                        refreshEmailTemplates(true, form, apiUrl!, setEmailTemplates, setSelectedEmailTemplate, setLoadingEmailTemplates)
                                    }
                                    form.setFieldValue("type", chosenTypes)
                                    if (!chosenTypes.includes("email")) {
                                        form.setFieldValue("subject", undefined)

                                    }
                                }}
                            >
                                <Grid align='center' mt={20} >

                                    <Grid.Col span={{ xs: 6 }}>
                                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                                            <Checkbox
                                                color="green"
                                                styles={{
                                                    input: { marginTop: 6 },
                                                    icon: { marginTop: 11 },
                                                }}
                                                value="email"
                                                // label="Email"
                                                label={
                                                    <Grid justify='center' align='center'>

                                                        <Grid.Col span={9}>

                                                            Email
                                                        </Grid.Col>


                                                    </Grid>
                                                }

                                            />


                                            {form.values.type.includes("email") &&
                                                <Grid justify='center' align='center' mt={20}>


                                                    <Grid.Col span={{ xs: 8 }} >
                                                        {loadingEmailTemplates ?
                                                            <Loader size={30} />
                                                            : <Select
                                                                label="Select Email template"
                                                                value={selectedEmailTemplate}
                                                                onChange={(value: string | null, option: any) => {
                                                                    setSelectedEmailTemplate(value)
                                                                    form.setFieldValue("selectedEmailTemplate", value)
                                                                }}
                                                                data={emailTemplates.map((template: any) => {
                                                                    return {
                                                                        ...template,
                                                                        value: template.id,
                                                                        label: template?.name
                                                                    }
                                                                })} />}

                                                    </Grid.Col>
                                                    <Grid.Col span={3} mt={25}>

                                                        <ActionIcon
                                                            disabled={loadingEmailTemplates}
                                                            onClick={async () => await refreshEmailTemplates(form.values.type.includes("email"), form, apiUrl!, setEmailTemplates, setSelectedEmailTemplate)

                                                            }
                                                            variant="subtle" aria-label="refres-templates">

                                                            <IconRefresh stroke={1.5} />
                                                        </ActionIcon>
                                                    </Grid.Col>
                                                </Grid>

                                            }

                                        </Card>
                                    </Grid.Col>
                                    <Grid.Col span={{ xs: 6 }}>
                                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                                            <Checkbox
                                                value="whatsapp"
                                                color="green"
                                                styles={{
                                                    input: { marginTop: 6 },
                                                    icon: { marginTop: 11 },
                                                }}
                                                label={
                                                    <Grid justify='center' align='center'>
                                                        <Grid.Col span={9}>
                                                            Whatsapp
                                                        </Grid.Col>
                                                    </Grid>
                                                }
                                            />
                                            {form.values.type.includes("whatsapp") &&

                                                <Grid justify='center' align='center' mt={20}>

                                                    <Grid.Col span={{ xs: 8 }} >
                                                        {loadingWhatsappTemplates ?
                                                            <Loader size={30} />
                                                            : <Select
                                                                label="Select whatsapp  template"
                                                                value={selectedWhatsappTemplate?.value}
                                                                onChange={(value, option: any) => {
                                                                    setSelectedWhatsappTemplate(option)
                                                                    form.setFieldValue("selectedWhatsappTemplate", option)

                                                                    // MessageTextchanged(option?.types["twilio/text"] ? option?.types["twilio/text"]?.body : option?.types["twilio/call-to-action"]?.body)
                                                                }}
                                                                data={whatsappTemplates?.map((template: TwilioContentType) => {
                                                                    return {
                                                                        ...template,
                                                                        value: template.sid,
                                                                        label: template?.friendly_name
                                                                    }
                                                                })}
                                                            />}
                                                    </Grid.Col>
                                                    <Grid.Col span={3} mt={25}>

                                                        <ActionIcon
                                                            disabled={loadingWhatsappTemplates}
                                                            onClick={async () => await refreshWhatsappTemplates(form.values.type.includes("whatsapp"), form, apiUrl!, setWhatsappTemplates, setSelectedWhatsappTemplate)

                                                            }
                                                            variant="subtle" aria-label="refres-templates">

                                                            <IconRefresh stroke={1.5} />
                                                        </ActionIcon>
                                                    </Grid.Col>
                                                </Grid>


                                            }
                                        </Card>
                                    </Grid.Col>
                                    <Grid.Col span={{ xs: 6 }}>

                                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                                            <Checkbox
                                                color="green"

                                                value="sms"
                                                // label="SMS"
                                                label={
                                                    <Grid justify='center' align='center'>
                                                        <Grid.Col span={12}>

                                                            SMS
                                                        </Grid.Col>


                                                    </Grid>
                                                }
                                            />
                                            {

                                                form.values.type.includes("sms") &&
                                                <Grid.Col span={{ xs: 12 }}>
                                                    <Textarea
                                                        label={"SMS Message"}
                                                        onChange={(e) => {
                                                            form.setFieldValue("smsMessage", e.target.value)
                                                            MessageTextchanged(e.target.value, setSMSSampleText)
                                                        }}
                                                        minRows={4}
                                                    />
                                                    <Text fs="italic" c="dimmed" size="xs">
                                                        You can use variables like {"{{name_en}}"} and {"{{name_ar}}"}
                                                    </Text>
                                                    <p>
                                                        <Text size="xs">SAMPLE</Text>
                                                    </p>
                                                    <p>
                                                        <Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>{smsSampleText}</Text>
                                                    </p>

                                                </Grid.Col>
                                            }
                                        </Card>
                                    </Grid.Col>
                                    <Grid.Col span={{ xs: 6 }}>
                                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                                            <Checkbox
                                                color="green"

                                                value="customEmail"
                                                // label="SMS"
                                                label={
                                                    <Grid justify='center' align='center'>
                                                        <Grid.Col span={12}>

                                                            Customized Email
                                                        </Grid.Col>


                                                    </Grid>
                                                }
                                            />
                                            {form.values.type.includes("customEmail") && <Grid.Col span={{ xs: 12 }} >
                                                <Text>
                                                    Subject
                                                </Text>
                                                <Input
                                                    required

                                                    mt={10}
                                                    onChange={(e) => {
                                                        form.setFieldValue("emailSubject", e.target.value)

                                                    }}
                                                />
                                                <Divider my="md" w="100%" />
                                                <Grid.Col span={{ xs: 12 }}>
                                                    <Textarea
                                                        label={"Email Message"}
                                                        onChange={(e) => {
                                                            form.setFieldValue("emailMessage", e.target.value)
                                                            MessageTextchanged(e.target.value, setEmailSampleText)
                                                        }}
                                                        minRows={4}
                                                    />
                                                    <Text fs="italic" c="dimmed" size="xs">
                                                        You can use variables like {"{{name_en}}"} and {"{{name_ar}}"}
                                                    </Text>
                                                    <p>
                                                        <Text size="xs">SAMPLE</Text>
                                                    </p>
                                                    <p>
                                                        <Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>{emailSampleText}</Text>
                                                    </p>

                                                </Grid.Col>
                                            </Grid.Col>}
                                        </Card>

                                    </Grid.Col>
                                    <Grid.Col span={{ xs: 12 }}>
                                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                                            <Checkbox
                                                color="green"

                                                value="notification"
                                                // label="SMS"
                                                label={
                                                    <Grid justify='center' align='center'>
                                                        <Grid.Col span={12}>

                                                            Notification
                                                        </Grid.Col>


                                                    </Grid>
                                                }
                                            />
                                            {form.values.type.includes("notification") && <Grid.Col >
                                                {/* <Text>
                                                    Title
                                                </Text> */}
                                                <Input
                                                    required
                                                    placeholder='Title'
                                                    // mt={10}
                                                    onChange={(e) => {
                                                        form.setFieldValue("notificationTitle", e.target.value)

                                                    }}
                                                />
                                                <Divider my="md" w="100%" />
                                                {/* <Grid.Col span={{ xs: 12 }}> */}
                                                <Textarea
                                                    // label={"Notification Body"}
                                                    placeholder='Body'
                                                    required
                                                    onChange={(e) => {
                                                        form.setFieldValue("notificationBody", e.target.value)
                                                        MessageTextchanged(e.target.value, setNotificationSampleText)
                                                    }}
                                                    minRows={4}
                                                />
                                                <Text fs="italic" c="dimmed" size="xs">
                                                    You can use variables like {"{{name_en}}"} and {"{{name_ar}}"}
                                                </Text>
                                                <p>
                                                    <Text size="xs">SAMPLE</Text>
                                                </p>
                                                <p>
                                                    <Text c="blue" dir={form.getInputProps("language").value === "Arabic" ? "rtl" : "ltr"}>{notificationSampleText}</Text>
                                                </p>

                                                {/* </Grid.Col> */}
                                                <Divider my="md" w="100%" />
                                                <Grid align='left'>
                                                    <Grid.Col span={{ xs: 12 }} >
                                                        <Text>Data</Text>
                                                        <Select
                                                            // label="Select data"
                                                            placeholder='Select Data Type'
                                                            value={selectedNotificationDataType}
                                                            onChange={(value, option: any) => {
                                                                setSelectedNotificationDataType(value ?? "none");
                                                                // setSelectedWhatsappTemplate(option)
                                                                if (value == "none") {
                                                                    form.setFieldValue("notificationData", undefined);
                                                                } else if (value == "kyc") {
                                                                    form.setFieldValue("notificationData", {
                                                                        type: "kyc",
                                                                        value: ""
                                                                    });

                                                                } else {
                                                                    form.setFieldValue("notificationData", {
                                                                        type: value == "link" ? "link" : "fund",
                                                                        value: ""
                                                                    });
                                                                }


                                                                // MessageTextchanged(option?.types["twilio/text"] ? option?.types["twilio/text"]?.body : option?.types["twilio/call-to-action"]?.body)
                                                            }}
                                                            data={[
                                                                {
                                                                    value: "none",
                                                                    label: "None",
                                                                },
                                                                {
                                                                    value: "kyc",
                                                                    label: "KYC",
                                                                },
                                                                {
                                                                    value: "fund",
                                                                    label: "Fund",
                                                                },
                                                                {
                                                                    value: "link",
                                                                    label: "Link",
                                                                },
                                                            ]}
                                                        />
                                                    </Grid.Col>
                                                    {/* <Grid.Col span={3} mt={25}>

                                                    <Select
                                                        label="Select data"
                                                        value={selectedNotificationDataValue}
                                                        onChange={(value, option: any) => {
                                                            setSelectedNotificationDataValue(value);
                                                            form.setFieldValue("notificationData.value", value)

                                                            // setSelectedWhatsappTemplate(option)
                                                            // form.setFieldValue("selectedWhatsappTemplate", option)

                                                            // MessageTextchanged(option?.types["twilio/text"] ? option?.types["twilio/text"]?.body : option?.types["twilio/call-to-action"]?.body)
                                                        }}
                                                        data={[
                                                            {
                                                                value: "MMF",
                                                                label: "Money Market",
                                                            },
                                                            {
                                                                value: "gcc",
                                                                label: "Gulf fund",
                                                            },
                                                        ]}
                                                    />
                                                </Grid.Col> */}
                                                    <Grid.Col span={{ xs: 12 }}>
                                                        {selectedNotificationDataType == "fund" &&
                                                            <Select
                                                                // onChange={setFund}
                                                                //   disabled={pending}
                                                                // miw={400}
                                                                // maxDropdownHeight={400}
                                                                value={selectedFund?.id}
                                                                data={transformFundsToGroupedData(funds)}
                                                                placeholder="Choose fund type"
                                                                onChange={(value) => {
                                                                    //get the fund object
                                                                    let fund = funds.find((fund) => fund.id === value);
                                                                    if (!fund) return;
                                                                    form.setFieldValue("notificationData.value", fund.id)
                                                                    setSelectedFund(fund as Fund);
                                                                    // recalcAmount();
                                                                }}

                                                            />
                                                        }
                                                        {selectedNotificationDataType == "link" &&
                                                            <TextInput
                                                                required
                                                                placeholder='Link'
                                                                // mt={10}
                                                                {...form.getInputProps('notificationDataLink')}
                                                                value={form.values.notificationDataLink}
                                                                error={form.errors.notificationDataLink}
                                                                onChange={(e) => {
                                                                    form.setFieldValue("notificationData.value", e.target.value)
                                                                }}
                                                            />
                                                        }
                                                    </Grid.Col>
                                                </Grid>

                                            </Grid.Col>}
                                        </Card>

                                    </Grid.Col>

                                </Grid>
                            </Checkbox.Group>
                        </Grid.Col>



                        <Divider my="md" w="100%" />






                        <Grid.Col span={{ xs: 12 }} >
                            {
                                SMSDocID?.map((smsDoc) => <MessagesSent docID={smsDoc} />)
                            }
                        </Grid.Col>

                        <Grid.Col span={{ xs: 12 }} >
                            <Text c={"red"}>
                                {errMsg}
                            </Text>
                        </Grid.Col>

                        <Grid.Col span={{ xs: 3 }} >
                            <Button
                                loading={loadingSubmit}
                                color='green'
                                type='submit'
                                fullWidth

                            >
                                Create
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Grid.Col>

        </Grid >
    )
}

export default CreateCampaign