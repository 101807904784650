import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';

const FundCatalougeViewer = () => {

    const storage = getStorage()
    const fileRef = ref(storage, "portal-files/KFH Capital Fund Catalogue - (Q2 2024) .pdf");

    const [docURL, setDocURL] = useState<any>()
    useEffect(() => {

        const getFile = async () => {
            try {

                const url = await getDownloadURL(fileRef); // Get the file's download URL


                setDocURL(url);
            } catch (error) {
                console.error("Error fetching the file URL:", error);
            }
        }
        getFile();

    }, [])


    return (
        <div style={{
            width: "100%",
            // background: "yellow",
            // display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
        }}>
            <div
                style={{
                    color: "red",
                    width: "100%",
                    background: "yellow",
                    marginBottom: 10,
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "22px"
                }}>

                This is an          {" "}
                <span
                    style={{
                        textDecoration: "underline",
                        fontWeight: "bolder",
                    }}
                >
                    Internal
                </span>
                {" "}
                and
                {" "}
                <span
                    style={{
                        textDecoration: "underline",
                        fontWeight: "bolder",
                    }}
                >

                    Confidential
                </span>
                {" "}
                Document and
                {" "}
                <span
                    style={{
                        textDecoration: "underline",
                        fontWeight: "bolder",
                    }}
                >

                    should not
                </span>
                {" "}
                be shared with unauthorized users.
            </div>
            <div
                style={{
                    color: "red",
                    width: "100%",
                    // background: "yellow"
                }}
            >


                {!docURL ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 100
                    }}>
                        <Loader />
                    </div>
                    : <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>

                        <Viewer
                            renderError={(error) => {


                                return <>
                                    Invalid File
                                </>
                            }
                            }
                            fileUrl={docURL}

                            defaultScale={SpecialZoomLevel.PageWidth}
                        />
                    </Worker>}


            </div>
        </div>
    )
}

export default FundCatalougeViewer